import React, { Fragment, useCallback, useState } from 'react'
import Modal from '../../Modal';
import Block from '../../Block';
import EditGroup from '../EditGroup';
import "./GroupItem.scss"


export default function GroupItem() {
  const [showEdit, setShowEdit] = useState(false);

  const [editModal, setEditModal] = useState(false);

  const showEditModal = useCallback(() => {
    setEditModal(true);
  }, []);

  const onClose = useCallback(() => {
    setEditModal(false);
  }, []);



  return (
    <div className="GroupItem">
      <div className='group-name'>Family</div>

      <div className='wrapper' onMouseEnter={() => setShowEdit(true)}
        onMouseLeave={() => setShowEdit(false)}>
        {
          showEdit ? (
            <div className='edit-wrapper' onClick={showEditModal}>
              <div className='edit' />
            </div>

          ) : (
            <div className='details'>
              <div className='icon' />
              <div className='users-number'>3</div>


            </div>


          )
        }
      </div>
      <Modal isOpen={editModal} title="Create custom group" onClose={onClose}>
        <EditGroup />
      </Modal>


    </div>
  )
}
