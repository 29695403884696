import React from "react";
import { useSelector } from "react-redux";

function NotificationList() {
  const { notifications } = useSelector((store) => store.notification);
  return (
    <>
      {notifications?.map((item, index) => {
        return !item?.message_read ? (
          <div key={index} className="flex list flex-js-fs">
            <div className="text">
              <span>{item?.message}</span>
              <span className="description">{item?.hyperlink}</span>
              <br />
              {/* <span className="temps">{item?.date_create}</span> */}
            </div>
          </div>
        ) : null;
      })}
    </>
  );
}

export default NotificationList;
