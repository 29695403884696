import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import Swal from "sweetalert2";
import { getNotifications } from "../../../Redux/slices/notification";

const NewNotification = ({ setIsNewNotification }) => {
  const dispatch = useDispatch();

  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [description, setDescription] = useState("");
  const [message, setMessage] = useState("");
  const [hyperlink, setHyperlink] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef(null);
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const handleUserSelection = (username) => {
    setSelectedUser(username);
    setIsDropdownOpen(false);
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };
  useEffect(() => {
    getUsers();
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const getUsers = () => {
    const urlUsers = `${routes.routes.back_end.admin.users.users.url}`;
    const methodUsers = `${routes.routes.back_end.admin.users.users.method}`;

    ApiService.fetchApiData(urlUsers, methodUsers, "user")
      .then((result) => {
        setAllUsers(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  useEffect(() => {
    getUsers();
  }, []);

  const createNotification = () => {
    let data = {
      description: description,
      message: message,
      user: selectedUser,
      hyperlink: hyperlink,
    };

    if (
      description !== "" &&
      message !== "" &&
      selectedUser !== null &&
      hyperlink !== ""
    ) {
      const selectedUserId = allUsers.find(
        (user) => user.username === selectedUser
      )?.id;
      data.user = selectedUserId;

      const route = `${routes.routes.back_end.admin.users.notifications.url}`;
      const method = `${routes.routes.back_end.admin.users.notifications.method}`;
      console.log("data before create notif", data);
      ApiService.sendingPutData(route, method, data, "user")
        .then((result) => {
          setIsNewNotification(false);
          Swal.fire({
            title: "NOTIFICATION CREATED!",
            text: "SUCCESS",
            icon: "success",
            timer: 1500,
            timerProgressBar: true,
          }).then(() => {
            // window.location.reload();
          });
        })
        .catch(() => {
          console.log("Error");
        });
    }
  };

  return (
    <div className="new-notification">
      <div className="form">
        <div className="container-input">
          <span className="label">Description</span>
          <input
            className="input"
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
        <div className="container-input">
          <span className="label">Message</span>
          <textarea
            className="input-text"
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="container-input">
          <span className="label">User</span>
          <div className="dropdown input" ref={dropdownRef}>
            <button className="dropdown-toggle" onClick={toggleDropdown}>
              {selectedUser || "Select User"}
            </button>
            {isDropdownOpen && (
              <ul className="dropdown-menu">
                {allUsers.map((user) => (
                  <li
                    key={user.id}
                    onClick={() => handleUserSelection(user.username)}
                  >
                    {user.username}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="container-input">
          <span className="label">Hyperlink</span>
          <input
            className="input"
            onChange={(e) => setHyperlink(e.target.value)}
          />
        </div>
      </div>

      <button className="button" onClick={createNotification}>
        Create notification
      </button>
    </div>
  );
};

export default NewNotification;
