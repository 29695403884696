import React, { useEffect, useState } from "react";
import dot from "../../../Assets/vectors/users/dot.svg";
import search from "../../../Assets/vectors/users/search.svg";
import pp_p from "../../../Assets/vectors/puzzles/pp_p.svg";
import watch from "../../../Assets/vectors/transactions/watch.svg";
import sell from "../../../Assets/vectors/transactions/sell.svg";
import bought from "../../../Assets/vectors/transactions/bought.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";

const Transactions = () => {
  const [allTransactions, setAllTransactions] = useState(null);
  const [valueTabActive, setValueTabActive] = useState("transactions");
  const [saleData, setSaleData] = useState(null);
  const [boughtData, setBoughtData] = useState(null);
  const [currentDataToDisplay, setCurrentDataToDisplay] = useState(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    switch (valueTabActive) {
      case "bought":
        setCurrentDataToDisplay(
          allTransactions.filter((transaction) => transaction["type"] === "BUY")
        );
        break;
      case "sale":
        setCurrentDataToDisplay(
          allTransactions.filter(
            (transaction) => transaction["type"] === "SELL"
          )
        );
        break;
      default:
        setCurrentDataToDisplay(allTransactions);
        break;
    }
  }, [valueTabActive]);

  const fetchTransactions = () => {
    const urlTransactions = `${routes.routes.back_end.admin.transactions.transactions.url}`;
    const methodTransactions = `${routes.routes.back_end.admin.transactions.transactions.method}`;

    ApiService.fetchApiData(urlTransactions, methodTransactions, "transaction")
      .then((result) => {
        console.log("result : ", result);
        setAllTransactions(result);
        setCurrentDataToDisplay(result);
        setSaleData(result.filter((puzzle) => puzzle["type"] === "SELL"));
        setBoughtData(result.filter((puzzle) => puzzle["type"] === "BUY"));
      })
      .catch(() => {
        console.log("error : ");
      });

      
  };
  const handleTab = (value) => {
    setValueTabActive(value);
  };

  return (
    <div className="flex flex-fd-c  transactions">
      <div className="flex users_select flex-fd-c flex-js-fs">
        <div className="flex users_select_content">
          <div
            className={`flex users_select_item  ${
              valueTabActive === "transactions"
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
            }`}
          >
            <span
              onClick={() => handleTab("transactions")}
              className="users_select_item_text"
            >
              All transactions
            </span>
            <img className="users_select_item_img" src={dot} alt="dot" />
            <span className="users_select_item_number">
              {allTransactions && allTransactions.length}
            </span>
          </div>
          {/*<div
            className={`flex users_select_item  ${
              valueTabActive === "sale"
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
            }`}
          >
            <span
              className="users_select_item_text"
              onClick={() => handleTab("sale")}
            >
              Sale
            </span>
            <img className="users_select_item_img" src={dot} alt="dot" />
            <span className="users_select_item_number">
              {saleData && saleData.length}
            </span>
          </div>*/}
          {/*<div
            className={`flex users_select_item  ${
              valueTabActive === "bought"
                ? "users_select_tab-active"
                : "users_select_tab-inactive"
            }`}
          >
            <span
              className="users_select_item_text"
              onClick={() => handleTab("bought")}
            >
              Bought
            </span>
            <img className="users_select_item_img" src={dot} alt="dot" />
            <span className="users_select_item_number">
              {boughtData && boughtData.length}
          </span>
          </div>*/}
        </div>
        <div className="flex transactions_select_content_down">
          <div className="flex flex-js-fs transactions_select_content_down_line"></div>
          <div className="flex flex-js-fs transactions_select_content_down_input">
            <input
              className="transactions_select_content_down_input_input"
              placeholder="search in this board"
            />
            <img
              className="transactions_select_content_down_input_img"
              src={search}
              alt="search"
            />
          </div>
        </div>
      </div>

      <div className="transactions_header">
        <div className="flex transactions_header_row">
          <div className="transactions_header_row_type">Buy/Sell</div>
          <div className="transactions_header_row_date">Side</div>
          <div className="transactions_header_row_date">Date</div>
          <div className="transactions_header_row_date">Price</div>
          <div className="transactions_header_row_date">Order type</div>
          <div className="transactions_header_row_date">Asset</div>
          {/*<div className="transactions_header_row_puzzle">Puzzle</div>*/}
          <div className="transactions_header_row_puzzle">User</div>
          <div className="transactions_header_row_date">Order currency</div>
          <div className="transactions_header_row_date">Progress</div>
          <div className="transactions_header_row_date">Total</div>
          <div className="transactions_header_row_status">Status</div>
        </div>
      </div>

      {/*currentDataToDisplay &&
        currentDataToDisplay
          .slice(0)
          .reverse()
          .map((value, key) => {
            return (
              <>
                <div className="flex flex-fd-c transactions_list">
                  <div className="flex transactions_list_row flex-js-fs">
                    <div className="flex transactions_list_row_buy-sell ">
                      {value.type == "SELL" ? (
                        <>
                          <img src={sell} />
                          <span>Sale</span>
                        </>
                      ) : (
                        <>
                          <img src={bought} />
                          <span>Bought</span>
                        </>
                      )}
                    </div>
                    <div className="users_list_row_text transactions_list_row_date resize">
                      {value.date.substr(0, 10)}
                    </div>
                    <div className="users_list_row_text transactions_list_row_date resize">
                      ${parseFloat(value.price).toFixed(3)}
                    </div>
                    <div className="flex transactions_list_row_puzzle ">
                      <img src={watch} />
                      <span>title</span>
                    </div>
                    <div className="flex users_list_row_text transactions_list_row_user ">
                      <img
                        className="users_list_row_text puzzles_list_row_creator_img"
                        src={pp_p}
                        alt="pp_p"
                      />
                      <span>Allan08</span>
                    </div>
                    <div className="users_list_row_text transactions_list_row_status ">
                      confirmed
                    </div>
                  </div>
                </div>
                <div className="users_list_line"></div>
              </>
            );
          })*/}

      <>
        <div className="flex flex-fd-c transactions_list">
          <div className="flex transactions_list_row flex-js-fs">
            <div className="flex transactions_list_row_buy-sell ">
              <>
                <img src={bought} />
                <span>null</span>
              </>
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="flex transactions_list_row_puzzle ">
              <img src={watch} />
              <span>title</span>
            </div>
            <div className="flex users_list_row_text transactions_list_row_user ">
              <img
                className="users_list_row_text puzzles_list_row_creator_img"
                src={pp_p}
                alt="pp_p"
              />
              <span>Allan08</span>
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_date resize">
              null
            </div>
            <div className="users_list_row_text transactions_list_row_status ">
              confirmed
            </div>
          </div>
        </div>
        <div className="users_list_line"></div>
      </>
    </div>
  );
};

export default Transactions;
