import React, { useState, useEffect } from "react";
import littleBanner2 from "../../../Assets/img/banner/littleBanner2.png";
import littleBanner1 from "../../../Assets/img/banner/littleBanner1.png";
import banner3 from "../../../Assets/img/banner/banner3.png";
import banner1 from "../../../Assets/img/banner/banner1.png";
import banner2 from "../../../Assets/img/banner/banner2.png";
import { Fragment } from "react";
const Preview = ({ allData, bannerSelected }) => {
  const handleBannerSelected = (value) => {
    bannerSelected(value);
  };
  return (
    <div className="flex flex-fd-c banner_preview">
      <div className="flex flex-fd-c banner_preview_content">
        <div className="banner_preview_content_title">Homepage</div>
        <div className="flex banner_preview_content_img">
          {allData.map((value, index) => {
            if (value.tab.includes("homepage")) {
              return (
                <Fragment key={index}>
                  {/* <img
                    className=" banner_preview_content_img_item"
                    alt=""
                    src={value.img}
                  /> */}
                  <div className="banner_preview_content_img_item my-background preview-background"
                    style={{
                      backgroundImage: `url(${value.img})`
                    }}
                    //onClick={() => handleBannerSelected(value)}
                  />
                </Fragment>
              );
            }
          })}
        </div>
      </div>

      <div className="flex flex-fd-c banner_preview_content">
        <div className="banner_preview_content_title">All puzzles</div>
        <div className="flex banner_preview_content_img">
          {allData.map((value, index) => {
            if (value.tab.includes("allpuzzles")) {
              return (
                <Fragment key={index}>

                  <div className="banner_preview_content_img_item my-background preview-background"
                    style={{
                      backgroundImage: `url(${value.img})`
                    }}
                    //onClick={() => handleBannerSelected(value)}
                  />
                </Fragment>
              );
            }
          })}
        </div>
      </div>

      <div className="flex flex-fd-c banner_preview_content">
        <div className="banner_preview_content_title">New puzzles</div>
        <div className="flex banner_preview_content_img">
          {allData.map((value, index) => {
            if (value.tab.includes("Newpuzzles")) {
              return (
                <Fragment key={index}>

                  <div className="banner_preview_content_img_item my-background preview-background"
                    style={{
                      backgroundImage: `url(${value.img})`
                    }}
                    //onClick={() => handleBannerSelected(value)}
                  />
                </Fragment>
              );
            }
          })}
        </div>
      </div>

      <div className="flex flex-fd-c banner_preview_content">
        <div className="banner_preview_content_title">Puzzles market</div>
        <div className="flex banner_preview_content_img">
          {allData.map((value, index) => {
            if (value.tab.includes("Puzzlesmarket")) {
              return (
                <Fragment key={index}>

                  <div className="banner_preview_content_img_item my-background preview-background"
                    style={{
                      backgroundImage: `url(${value.img})`
                    }}
                    //onClick={() => handleBannerSelected(value)}
                  />
                </Fragment>
              );
            }
          })}
        </div>
      </div>
      {console.log("allData : ", allData)}
    </div>
  );
};

export default Preview;
