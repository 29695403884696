const userData = (state = new Object(), action) => {
  switch (action.type) {
    case "USER_DATA":
      return (state = action.payload);
    default:
      return (state = state);
  }
};

export default userData;
