import React, { useState } from 'react'
import './UserItem.scss'
export default function UserItem() {
  const [userCheck, setUserCheck] = useState(false)

  const handleUserCheck = () => {
     setUserCheck(!userCheck)
  }

  return (
    <div className='users-list'>
      <div className='user-item-container'>
        <div className='user-item'>
          <div className='profile-pic' />
          <label>eddy97</label>
        </div>
        <div className={"check-user " + (userCheck ? 'checked' : 'unchecked') } onClick={handleUserCheck} />
      </div>
    </div>
  )
}
