import React, { useEffect, useMemo, useState } from "react";
import NewFaq from "./NewFAQ";
import { Link } from "react-router-dom";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import FAQItem from "./FAQItem";
import './FAQ.scss'

const fetchFaqs = async () => {
	const url = `${routes.routes.back_end.admin.users.faqs.url}`;
	const method = `${routes.routes.back_end.admin.users.faqs.method}`;
	return await ApiService.fetchApiData(url, method, "user")
}

const FAQ = () => {
  const [isNewFaq, setIsNewFaq] = useState(false)
  const [faqs, setFaqs] = useState([])
  const [toAnswer, setToAnswer] = useState()
  const [activePage, setActivePage] = useState("help")

  const handlePage = page => {
    setActivePage(page);
  };
  const update = useMemo(() => async () => setFaqs(await fetchFaqs()), [])

  useEffect(() => { update() }, [])

  return (
    <div className="FAQ">
        {!isNewFaq && (
          <React.Fragment>
            <div className="faq_search flex flex-fd-c flex-ai-c">
              <div className="search_label flex flex-fd-c flex-ai-c">
                <h3>How can we help you ?</h3>
                <span>Search for a topic in the FAQ or ask a question in the community.</span>
              </div>
              <div className="search_bar flex flex-jc-c">
                <div className="search_icon"></div>
                <input type='text' placeholder="search in FAQ..." />
              </div>
            </div>
            <div className="faq_menu">
              <div className="faq_sort">Best trending questions</div>
              <div className="add_topic">
                <div className="add_label"
                  onClick={() => setIsNewFaq(true)}>Add topic</div>
              </div>
            </div>

            <div className="faq-container">
              {
                faqs.map(faq => {
                  return (<FAQItem
                    faq={faq}
                    update={update}
                    {...{ setToAnswer, toAnswer }}
                  />)
                })
              }
            </div>
          </React.Fragment>
        )}
        {
          isNewFaq &&  <NewFaq setIsNewFaq={setIsNewFaq} />
        }

    
    </div>
  );
};



export default FAQ;
