import React, { useEffect, useState } from "react";
import puzzle_exemple from "../../../../Assets/img/Mypuzzles/puzzle_exemple.png";
import watch_conf from "../../../../Assets/img/Mypuzzles/watch_conf.png";
import routes from "../../../../Routes/routes.json";
import ApiService from "../../../../Utils/ApiService";
import confirm_check from "../../../../Assets/img/Account/confirm_check.svg";
import { useSelector, useDispatch } from "react-redux";
import CircleProgressBar from "../../../common/CircleProgressBar";

const Confirmation = ({
  allDataStep1,
  allMedia,
  fileSpecification,
  allDataSpecs,
}) => {
  const user = useSelector((state) => state.Auth.user);
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const percentage = 100;
  const [progressBar, setProgressBar] = useState(0);
  const [firstClass, setFirstClass] = useState("");
  const [characters, setCharacters] = useState(
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  );
  const [sellingFee, setSellingFee] = useState(0);
  const [finalListingPrice, setFinalListingPrice] = useState(
    allDataStep1.price
  );
  const myPrice = allDataStep1.price;

  const handleSellingFeeChange = (event) => {
    const fee = parseFloat(event.target.value);
    if (!isNaN(fee)) {
      setSellingFee(fee);
      setFinalListingPrice(myPrice + fee);
    } else {
      setSellingFee("");
      setFinalListingPrice(myPrice);
    }
  };

  console.log("all data specs in confirm: ", allDataSpecs);
  const generateRef = (length) => {
    let result = "";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  useEffect(() => {
    // fetch classes
    const urlClasses = `${routes.routes.back_end.admin.puzzles.classes.url}`;
    const methodClasses = `${routes.routes.back_end.admin.puzzles.classes.method}`;

    console.log("user.id : ", user.id);

    ApiService.fetchApiData(urlClasses, methodClasses, "puzzle")
      .then((result) => {
        console.log("result : ", result);
        setFirstClass(result[0]);
      })
      .catch(() => {
        console.log("error : ");
      });
  }, []);

  const createMiles = (ref) => {
    const urlCreateMiles = `${routes.routes.back_end.puzzle.create_miles.url}`;
    const methodCreateMiles = `${routes.routes.back_end.puzzle.create_miles.method}`;
    let data = {};

    data.day_basis = allDataStep1.day_basis;
    data.puzzle = ref;
    data.status = "live";
    data.miles_param = "test";
    data.minimal_days_booking = 10;
    data.minimal_days_interval = 3;
    ApiService.sendingPutData(urlCreateMiles, methodCreateMiles, data, "puzzle")
      .then((result) => {
        console.log("result create miles ", result);
      })
      .catch(() => {
        console.log("error");
      });
  };

  const handleCreate = () => {
    //TODO create puzzle
    const urlCreate = `${routes.routes.back_end.admin.puzzles.create_puzzle.url}`;
    const methodCreate = `${routes.routes.back_end.admin.puzzles.create_puzzle.method}`;
    console.log("class : ", firstClass);
    console.log("all data step 1 : ", finalListingPrice);
    let data = {};
    data.initial_price = finalListingPrice;
    data.description = allDataStep1.description;
    data.title = allDataStep1.title;
    data.reference = generateRef(12);
    data.puzzle_class = firstClass.name;
    data.puzzle_owner_user_id = user.id;
    data.currency_code = allDataStep1.currency_code;
    data.description_specs = allDataSpecs.description_specs;
    data.link_specs = allDataSpecs.link_specs;
    data.state = "to approve";
    console.log("data before create : ", data);
    let filesData = allMedia;
    if (fileSpecification) {
      filesData.push(fileSpecification);
    }
    ApiService.fetchApiFormData(
      urlCreate,
      methodCreate,
      data,
      allMedia,
      "puzzle"
    )
      .then((result) => {
        setConfirmSuccess(!confirmSuccess).then(() => {
          window.location.reload();
        });
      })
      .catch(() => {
        console.log("error");
      });

    //TODO create miles
  };
  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const updatePercentage = () => {
    setTimeout(() => {
      setProgressBar(progressBar + 1);
    }, 5);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() => {
    if (progressBar < percentage) updatePercentage();
  }, [progressBar]);
  return (
    <div className="mypuzzles_confirmation">
      <div className="mypuzzles_product">
        <div className="flex-elem flex-fd-c mypuzzles_product_first">
          <div className="">
            <span className="mypuzzles_product_second_product_information">
              Create a new puzzle
            </span>
          </div>
          <img className="mypuzzles_product_first_img" src={puzzle_exemple} />
        </div>

        <div className="flex-fd-c mypuzzles_product_second">
          <div className="mypuzzles_product_second_product">
            <span className="mypuzzles_product_second_product_information">
              Confirmation
            </span>
          </div>
          <div className="mypuzzles_confirmation_content">
            <div className="mypuzzles_confirmation_content_all">
              <div className="flex-elem mypuzzles_confirmation_content_all_first">
                <div className="flex-elem mypuzzles_confirmation_content_all_first_left">
                  <img
                    className="mypuzzles_confirmation_content_all_first_left_img"
                    src={URL.createObjectURL(allMedia[0])}
                    alt="puzzle_1"
                  />
                  {/*<img src={watch_conf} alt="watch_conf" />*/}

                  <span>{allDataStep1.title}</span>
                </div>
                <div className="mypuzzles_confirmation_content_all_first_right">
                  ${allDataStep1.price}
                </div>
              </div>
              <div className="flex-elem mypuzzles_confirmation_content_all_down">
                <div className="">My price</div>
                <div>${allDataStep1.price}</div>
              </div>
              <div className="flex-elem mypuzzles_confirmation_content_all_down">
                <div>Selling fee</div>
                <div>
                  <input
                    className="puzzles-input"
                    type="number"
                    value={sellingFee}
                    onChange={handleSellingFeeChange}
                  />
                </div>
              </div>
              <div className="flex-elem mypuzzles_confirmation_content_all_down">
                <div>Final listing price</div>
                <div>${finalListingPrice}</div>
              </div>
            </div>
          </div>
          <div className="flex-elem mypuzzles_confirmation_btn">
            <button
              className="mypuzzles_confirmation_btn_content"
              onClick={() => handleCreate()}
            >
              Create the puzzle
            </button>
          </div>
        </div>
      </div>
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Puzzle created
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
  );
};

export default Confirmation;
