import React from "react";
import search from "../../../Assets/vectors/users/search.svg";
const Consumption = () => {
  return (
    <div className="flex flex-fd-c  transactions">
      <div className="flex users_select flex-fd-c flex-js-fs">
        <div className="flex users_select_content">
          <div className="users_select_item users_select_tab-active">
            <span
              //onClick={() => handleTab("transactions")}
              className="users_select_item_text"
            >
              Consumption
            </span>
          </div>
        </div>
        <div className="flex transactions_select_content_down">
          <div className="flex flex-js-fs transactions_select_content_down_line"></div>
          <div className="flex flex-js-fs transactions_select_content_down_input">
            <input
              className="transactions_select_content_down_input_input"
              placeholder="search in this board"
            />
            <img
              className="transactions_select_content_down_input_img"
              src={search}
              alt="search"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-fd-c">
        <div className="puzzles_header">
          <div className="flex puzzles_header_row">
            <div className="puzzles_header_row_market">User</div>
            <div className="puzzles_header_row_market">Miles</div>
            <div className="puzzles_header_row_market">Start Date</div>
            <div className="puzzles_header_row_market">Start time</div>
            <div className="puzzles_header_row_market">Delivery Adress</div>
            <div className="puzzles_header_row_market">Collection point</div>
            <div className="puzzles_header_row_status">End Date</div>
            <div className="puzzles_header_row_status">End Time </div>
            <div className="puzzles_header_row_status">Delivery Adress </div>
            <div className="puzzles_header_row_market">Collection point</div>
            <div className="puzzles_header_row_status">Fee currency </div>
            <div className="puzzles_header_row_status">Fee </div>
            <div className="puzzles_header_row_status">Deposit </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Consumption;
