import React, { useState, useMemo, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import ButtonProfil from "../../common/ButtonProfil/ButtonProfil";
import RadioInput from "../../common/RadioButton";
import SettingButton from "../../common/SettingButton";
import CustomGroupButton from "../../common/RadioButton/CustomGroup";
import Modal from "../../common/Modal";
import VerificationAccount from "../../common/VerificationAccount";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import CircleProgressBar from "../../common/CircleProgressBar";
import confirm_check from "../../../Assets/img/Account/confirm_check.svg";
import { updateUsers } from "../../../Redux/slices/user";
import InputSettings from "../../common/InputSettings";
import ChangePassword from "../../common/ChangePassword";

const PersonnalDetails = () => {
  const user = useSelector((state) => state.User);
  const dispatch = useDispatch();
  console.log("USER CONNECTE", user);

  const [allDataPrivacy, setAllDataPrivacy] = useState({});
  const [seeActivityCustomGroup, setSeeActivityCustomGroup] = useState([]);
  const [seeActivityBlockGroup, setSeeActivityBlockGroup] = useState([]);
  const [allPrivacy, setAllPrivacy] = useState(null);
  const [dataUsersSelected, setDataUsersSelected] = useState({});
  const [dataUsersBlockSelected, setDataUsersBlockSelected] = useState({});
  const [confirmSuccess, setConfirmSuccess] = useState(false);
  const [progressBar, setProgressBar] = useState(0);

  const handleSuccess = () => {
    setConfirmSuccess(!confirmSuccess);
  };

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const handleClick = useCallback(() => {
    console.log("click");
    setIsOpen(true);
  }, []);

  const handleClickPassword = useCallback(() => {
    setIsOpenPassword(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);
  const onClosePassword = useCallback(() => {
    setIsOpenPassword(false);
  }, []);

  const [activeChoiceMail, setAciveChoiceMail] = useState([]);
  const toggleActiveMail = useMemo(
    () => (key) => {
      setAciveChoiceMail([key]);
    },
    [activeChoiceMail]
  );

  const [activeChoicePhone, setAciveChoicePhone] = useState([]);
  const toggleActivePhone = useMemo(
    () => (key) => {
      setAciveChoicePhone([key]);
    },
    [activeChoicePhone]
  );
  const [activeChoiceUsername, setAciveChoiceUsername] = useState([]);
  const toggleActiveUsername = useMemo(
    () => (key) => {
      setAciveChoiceUsername([key]);
    },
    [activeChoiceUsername]
  );

  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phone_number);
  const [username, setUsername] = useState(user.username);

  const handleSaveEmail = () => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    data.email = email;
    ApiService.sendingPutData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
        setAciveChoiceMail([]); 
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
  const handleSavePhoneNumber = () => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    data.phone_number = phone;
    ApiService.sendingPutData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
        setAciveChoicePhone([]); 
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };
  const handleSaveUsername = () => {
    const routeUpdateUser = `${routes.routes.back_end.iconic_user.update_user.url}`;
    const methodUpdateUser = `${routes.routes.back_end.iconic_user.update_user.method}`;
    let data = {};
    data.username = username;
    ApiService.sendingPutData(
      routeUpdateUser + "/" + user.username,
      methodUpdateUser,
      data,
      "user"
    )
      .then((result) => {
        console.log("result after update user : ", result);
        dispatch(updateUsers(result));
        setAciveChoiceUsername([]); 
      })
      .catch((err) => {
        console.log("error : ", err.message);
      });
  };


  return (
    <div className="privacy">
      <div className="option-title">Account Info</div>
      <div className="option-wrapper">
        <div className="items">
          <div className="item-select">Profil</div>
          <div className="side">
            <ButtonProfil onSubmit={handleClick}>Verify my profil</ButtonProfil>
          </div>
          <Modal isOpen={isOpen} title="Account Verification" onClose={onClose}>
            <VerificationAccount />
          </Modal>
        </div>
      </div>
      <div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">E-mail adress</div>
            <div
              className="item-value"
              onClick={() => toggleActiveMail("mail")}
            >
              <span>{user.email}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoiceMail.includes("mail") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your new e-mail"
                    type="text"
                    name="mail"
                    onChange={(e) => {
                      setEmail(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton text="Save changes" onSubmit={handleSaveEmail}/>
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Phone Number</div>
            <div
              className="item-value"
              onClick={() => toggleActivePhone("phone")}
            >
              <span>{user.phone_number}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoicePhone.includes("phone") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your phone number"
                    type="number"
                    name="phone"
                    onChange={(e) => {
                      setPhone(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton text="Save changes" onSubmit={handleSavePhoneNumber}/>
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Username</div>
            <div
              className="item-value"
              onClick={() => toggleActiveUsername("username")}
            >
              <span>{user.username}</span>
              <span className="arrow" />
            </div>
          </div>
          {activeChoiceUsername.includes("username") && (
            <div className="choices">
              <div className="choices-listSetting">
                <div className="choice-content">
                  <InputSettings
                    placeholder="Enter your new username"
                    type="texte"
                    name="username"
                    onChange={(e) => {
                      setUsername(e.target.value.trim());
                    }}
                  />
                </div>
                <SettingButton text="Save changes" onSubmit={handleSaveUsername} />
              </div>
            </div>
          )}
        </div>
        <div className="option-wrapper">
          <div className="items">
            <div className="item-select">Password</div>
            <div className="item-value" onClick={handleClickPassword}>
              <span>Change</span>
              <span className="arrow" />
            </div>
            <Modal
              isOpen={isOpenPassword}
              title="Change your Password"
              onClose={onClosePassword}
            >
              <ChangePassword />
            </Modal>
          </div>
        </div>
      </div>
      {confirmSuccess && (
        <div className="flex">
          <div className="exchange_popup flex flex-fd-c flex-js-fs">
            <CircleProgressBar
              trailStrokeColor="#FD55BA"
              strokeColor="#9B8EFF"
              percentage={progressBar}
              innerText="complete"
              radioCircle1={10}
              radioCircle2={12}
              y={19}
              size={38}
              x={19}
              className=""
              withtext={false}
              strokeDasharray={`${progressBar} ${100 - progressBar}`}
              strokeDashoffset={25}
            />
            <img
              src={confirm_check}
              alt=""
              className="transferout_popup_success"
            ></img>
            <div className="transferout_popup_confirm_text flex flex-ai-c">
              {" "}
              Information saved
            </div>
          </div>
          <div id="overlay" onClick={() => handleSuccess()}></div>
        </div>
      )}
    </div>
  );
};

export default PersonnalDetails;
