import { combineReducers } from "redux";

import auth from "../slices/auth";
import banner from "../slices/banner";
import notification from "../slices/notification";
import faq from "../slices/faq";
import user from "../slices/user";
import userData from "./userData";

const rootReducer = combineReducers({
  Auth: auth,
  User: user,
  userData: userData,
  banner,
  notification,
  faq
});

export default rootReducer;
