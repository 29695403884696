import React, { useState } from "react";
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import { useSelector } from "react-redux";
import "./NewFAQ.scss";

const NewFAQ = ({ setIsNewFaq }) => {
  // const [isNewNotification, setIsNewNotification] = useState(false)
  const [question, setQuestion] = useState(null);
  const { user } = useSelector((store) => store.Auth);
  const store = useSelector((store) => store);
  console.log("store", {
    store,
  });

  const createFaq = () => {
    let data = {
      question,
      owner: user.id,
    };
    const route = `${routes.routes.back_end.admin.users.faq.url}`;
    const method = `${routes.routes.back_end.admin.users.faq.method}`;

    ApiService.sendingPutData(route, method, data, "user")
    .then((result) => {
      setIsNewFaq(false);
      console.log("data ", data);
    });
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <span className="label">Topic</span>
          <textarea
            className="input"
            onChange={(e) => setQuestion(e.target.value)}
          ></textarea>
        </div>
      </div>

      <button className="button" onClick={(e) => question && createFaq()}>
        Save Topic
      </button>
      <button className="button" onClick={(e) => setIsNewFaq(false)}>
        Cancel
      </button>
    </div>
  );
};

export default NewFAQ;
