import React from "react";
import { useState } from "react";
import password_hidden from "../../../Assets/img/password_hidden.svg";
import Button from "../Button";
import Input from "../Input";

const ChangePassword = () => {
  const [actualPassword, setActualPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const hidePassword = (id) => {
    let password_input = document.getElementById(id);
    if (password_input.type === "text") {
      password_input.type = "password";
    } else {
      password_input.type = "text";
    }
  };

  const handleChangePassword = () => {
    console.log("eto");

    let data = new Object();
    data.actualPassword = actualPassword;
    data.newPassword = newPassword;
    data.confirmPassword = confirmPassword;

    //const route = `${routes.routes.back_end.iconic_user.contact.url}`;
    //const method = `${routes.routes.back_end.iconic_user.contact.method}`;
  };

  return (
    <form className="contact">
      <Input
        type="password"
        label="Actual Password"
        className={"connection_form_input"}
        placeholder="Choose your password"
        id="password_input"
        onChange={(e) => {
          setActualPassword(e.target.value.trim());
        }}
      >
        <img
          draggable="false"
          src={password_hidden}
          alt=""
          className="connection_form_hide"
          onClick={() => hidePassword("password_input")}
        />
      </Input>

      <Input
        type="password"
        label="New Password"
        className={"connection_form_input"}
        placeholder="Enter your new password"
        id="password_input"
        onChange={(e) => {
          setNewPassword(e.target.value.trim());
        }}
      >
        <img
          draggable="false"
          src={password_hidden}
          alt=""
          className="connection_form_hide"
          onClick={() => hidePassword("password_input")}
        />
      </Input>

      <Input
        type="password"
        label="New Password Confirm"
        className={"connection_form_input"}
        placeholder="Enter again your new password"
        id="password_input"
        onChange={(e) => {
          setNewPassword(e.target.value.trim());
        }}
      >
        <img
          draggable="false"
          src={password_hidden}
          alt=""
          className="connection_form_hide"
          onClick={() => hidePassword("password_input")}
        />
      </Input>

      <div className="submit-btn">
        <Button onSubmit={() => handleChangePassword()}>Save Password</Button>
      </div>
    </form>
  );
};

export default ChangePassword;
