import React, { useState, useMemo } from 'react'
import FaqAnswer from "../FAQAnswer";
import AnswerText from "../AnswerText";
import { useSelector } from 'react-redux'
import './FAQItem.scss'

const TagContent = (props) => {
	const tags = props.tags
	const bgColor = props.bgColor
	return (
		<div className="tag-content" style={{ backgroundColor: bgColor }}>{tags}</div>
	)
}

const formatter = new Intl.DateTimeFormat("en-US")




const FaqItem = ({ faq, setToAnswer, toAnswer, update }) => {
	const { id, question, answers, date, owner } = faq
	const [showedAnswers, setshowedAnswers] = useState()
	const { user } = useSelector((store) => store.Auth)

	// const showTextAnswer = (e) => {
	// 	setTextAnswer(current => !current)
	// }

	const best = answers.reduce((best, answer) => {
		if (!best) return answer
		return best.likes.length < answer.likes ? answer : best
	}, null)
	const likes = useMemo(() => answers.reduce((total, {likes}) => likes.length + total, 0), [answers])
	console.log('best' , best)
	console.log("likes" , likes)

	return <div key={id} className="FAQItem">
		<div className="faq-item-header">
			<h3 className="item-title">{question}</h3>
			<div className="tag-list">
				<TagContent tags='#community' bgColor='rgba(64, 72, 255, 1)' />
			</div>
		</div>
		<div className="users-content">
			<div className="users-name">Best answer by <span>{best && best.user && "@" + best.user.username}</span></div>
			<div className="users-answer">
				{best ? best.content : "No comments yet"}
			</div>
		</div>
		<div className="users-info">
			<div className="users-profile">
				<div className="profile-pic">
				</div>
				<div className="profile-info">
					<div className="profile-name">{owner && '@' + owner.username}</div>
					<div className="post-time">Posted {formatter.format(new Date(date))}</div>
				</div>
			</div>
			<div className="cta-buttons">
				<div className='like-length'>
					{likes} like{likes > 1 && 's'}
				</div>
				{
					answers.length > 0 && <div className="see-answer" onClick={() => setshowedAnswers(showedAnswers === id ? null : id)}>
						{showedAnswers === id ? "Hide" : "See"} {answers.length} answer{answers.length > 1 && "s"}
					</div>
				}
				<div className="reply" onClick={() => setToAnswer(id)}>Reply</div>
				{user.isAuthenticated && user.isAdmin (<div className="delete">delete</div>)}
			</div>
		</div>
		{
			toAnswer == id && (
				<AnswerText
					faq={id}
					close={() => {
						setToAnswer(false)
						update()
					}}
				/>
			)
		}
		{
			showedAnswers == id && (
				answers.map(answer => {
					return <FaqAnswer answer={answer} update={update} />
				})

			)
		}
	</div>


}

export default FaqItem
