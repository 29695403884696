import React from 'react';
import { Link } from 'react-router-dom';

import logout_icon from '../../../Assets/vectors/connection/logout.svg';
import logo from '../../../Assets/vectors/Navbar/logo.svg';
import useAuth from '../../../hooks/useAuth';
import routes from '../../../Routes/routes.json';
import ApiService from '../../../Utils/ApiService';
import Notification from '../../common/Notification';

const Navbar = () => {
  const { logout } = useAuth();
  const handledisconnected = () => {
    logout();
    const route = `${routes.routes.back_end.admin.users.logout.url}`;
    const method = `${routes.routes.back_end.admin.users.logout.method}`;
    ApiService.postWithoutData(route, method, 'user').then((result) => {
      console.log('logout result   : ', result);
    });
  };

  return (
    <div className="navbar ">
      <div className="navbar_content flex">
        <div className="navbar_content_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="navbar_content_links flex">
          <div className="navbar_content_links_content navbar_content_links_content-user">
            <Link to="/users">
              <div className="navbar_content_links_item">Users</div>
            </Link>
          </div>
          <div className="navbar_content_links_content-puzzle navbar_content_links_content">
            <Link to="/puzzles">
              <div className="navbar_content_links_item">Puzzles</div>
            </Link>
          </div>
          <div className="navbar_content_links_content-transactions navbar_content_links_content">
            <Link to="/transactions">
              <div className="navbar_content_links_item">Transactions</div>
            </Link>
          </div>
          <Link to="/banner">
            <div className="navbar_content_links_item">Banner</div>
          </Link>
          <Link to="/currency">
            <div className="navbar_content_links_item">Currency</div>
          </Link>
          <Link to="/transfert">
            <div className="navbar_content_links_item">transfert</div>
          </Link>
          <Link to="/pieces">
            <div className="navbar_content_links_item">Piece exchanges</div>
          </Link>
          <Link to="/consumption">
            <div className="navbar_content_links_item">Mile consumption</div>
          </Link>
          {/* <Link to="/faqs">
            <div className="navbar_content_links_item">Faqs</div>
          </Link> */}
          <Link to="/settings">
            <div className="navbar_content_links_item">Settings</div>
          </Link>
          <Link to="/notifications">
            <div className="navbar_content_links_item">Notifications</div>
          </Link>

          {/* Notification menu */}
          <Notification />
          {/* --*--*--*--*--*-- */}

          {/*<Link to="/">
            <div
              className="navbar_content_links_item"
              onClick={() => handledisconnected()}
            >
              log out
            </div>
          </Link>*/}
        </div>
        <div className="navbar_content_notif flex flex-js-fe">
          {/* <img src={notif} alt="notif" /> */}
          <img
            className="navbar_content_notif_logout"
            src={logout_icon}
            alt="logout"
            onClick={() => handledisconnected()}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
