import React, { useEffect, useState } from "react";
import puzzle_exemple from "../../../../Assets/img/Mypuzzles/puzzle_exemple.png";
import dollar from "../../../../Assets/vectors/Mypuzzles/dollar.svg";
import down from "../../../../Assets/vectors/Mypuzzles/down.svg";
import checked from "../../../../Assets/vectors/Account/checked.svg";

const ProductInformation = ({ gotoStep2, quitCreation, fetchDataStep1 }) => {
  const [isSeller, setIsSeller] = useState(true);
  const [allDataStep1, setAllDataStep1] = useState({});
  const [titleValue, setTitleValue] = useState("");
  const [descriptionValue, setDescriptionValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [priceValue, setPriceValue] = useState(0.0);
  const [dayBasis, setDayBasis] = useState("");
  const [isAllWalletActive, setIsAllWalletActive] = useState(false);
  const [currentWallet, setCurrentWallet] = useState({
    name: "Dollar",
    currency: "USD",
  });
  const [currentWalletChecked, setCurrentWalletChecked] = useState("Dollar");
  const [allWallet, setAllWallet] = useState([
    {
      name: "Euro",
      currency: "EUR",
    },
    {
      name: "Etherum",
      currency: "ETH",
    },
    {
      name: "Dollar",
      currency: "USD",
    },
  ]);

  useEffect(() => {
    let data = allDataStep1;
    data.title = titleValue;
    data.description = descriptionValue;
    data.price = priceValue;
    data.is_seller = isSeller;
    data.link_product = linkValue;
    data.currency_code = currentWallet.currency;
    //data.day_basis = dayBasis;
    console.log("all data product information  : ", data);
    setAllDataStep1(data);
  }, [titleValue, descriptionValue, priceValue, isSeller, linkValue, dayBasis]);

  const handleNext = () => {
    if (allDataStep1.title == "" || allDataStep1.price == 0) {
      return;
    }
    fetchDataStep1(allDataStep1);
    gotoStep2();
  };
  const quit = () => {
    quitCreation();
  };

  const wallet_item = (name) => {
    return (
      <div
        className="flex-elem mypuzzles_product_second_price_currency_money_content"
        onClick={() => handleWalletSelected(name)}
      >
        <img
          className="mypuzzles_product_second_price_currency_money_img"
          src={dollar}
          alt="down"
        />
        <span className="mypuzzles_product_second_price_currency_money_text">
          {name}
        </span>
        {isWalletValid(name)}
      </div>
    );
  };

  const handleWalletSelected = (name) => {
    setCurrentWalletChecked(name);
    {
      allWallet.map((value, key) => {
        if (value.name === name) {
          setCurrentWallet(value);
        }
      });
    }
    setIsAllWalletActive(false);
  };

  const isWalletValid = (money) => {
    if (currentWalletChecked === money) {
      return <img src={checked} alt="" id="euro-img" className="" />;
    } else {
      return <img src={checked} alt="" id="euro-img" className="not_visible" />;
    }
  };
  return (
    <div className="mypuzzles_product">
      <div className="flex-elem flex-fd-c mypuzzles_product_first">
        <div className="">
          <span className="mypuzzles_product_second_product_information">
            Create a new puzzle
          </span>
        </div>
        <img className="mypuzzles_product_first_img" src={puzzle_exemple} />
      </div>
      <div className="flex-fd-c mypuzzles_product_second">
        <div className="mypuzzles_product_second_product">
          <span className="mypuzzles_product_second_product_information">
            Product information
          </span>
          <span className="mypuzzles_product_second_product_step">
            step 1 of 3
          </span>
        </div>

        <div className="flex-elem mypuzzles_product_second_all-seller">
          <div className="flex-elem flex-fd-c mypuzzles_product_second_seller">
            <div className="mypuzzles_product_second_seller_text">
              Product seller details
            </div>
            <div className="flex-elem mypuzzles_product_second_seller_group">
              <div
                className="flex-elem mypuzzles_product_second_seller_group_first mypuzzles_product_second_seller_group_item"
                onClick={() => setIsSeller(!isSeller)}
              >
                <div
                  className={`mypuzzles_product_second_seller_group_item_select  ${
                    isSeller &&
                    "mypuzzles_product_second_seller_group_item_selected"
                  }`}
                ></div>
                <span
                  className={`mypuzzles_product_second_seller_group_item_text-all  ${
                    isSeller
                      ? "mypuzzles_product_second_seller_group_item_text-active"
                      : "mypuzzles_product_second_seller_group_item_text-inactive"
                  }`}
                >
                  I am the seller
                </span>
              </div>
              <div
                className="flex-elem mypuzzles_product_second_seller_group_second mypuzzles_product_second_seller_group_item"
                onClick={() => setIsSeller(!isSeller)}
              >
                <div
                  className={`mypuzzles_product_second_seller_group_item_select  ${
                    !isSeller &&
                    "mypuzzles_product_second_seller_group_item_selected"
                  }`}
                ></div>
                <span
                  className={`mypuzzles_product_second_seller_group_item_text-all   ${
                    isSeller
                      ? "mypuzzles_product_second_seller_group_item_text-inactive"
                      : "mypuzzles_product_second_seller_group_item_text-active"
                  }`}
                >
                  I am not the seller
                </span>
              </div>
            </div>
          </div>
          {!isSeller && (
            <div className="flex-elem flex-fd-c mypuzzles_product_second_all-seller_link">
              <div className="mypuzzles_product_second_seller_text">
                Link the product on the current marketplace
              </div>
              <div className="mypuzzles_product_second_all-seller_link_input">
                <input
                  type="text"
                  className="mypuzzles_product_second_all-seller_link_input_content"
                  placeholder="Paste the url product link"
                  onChange={(e) => setLinkValue(e.target.value)}
                />
              </div>
            </div>
          )}
        </div>

        <div className="flex-elem flex-fd-c mypuzzles_product_second_title">
          <div className="mypuzzles_product_second_seller_text">Title</div>
          <div className="mypuzzles_product_second_title_input">
            <input
              type="text"
              className="mypuzzles_product_second_title_input_content mypuzzles_product_second_title_input_title"
              placeholder="what's the title of the puzzle ?"
              onChange={(e) => setTitleValue(e.target.value)}
            />
          </div>
        </div>

        <div className="flex-elem flex-fd-c mypuzzles_product_second_title">
          <div className="mypuzzles_product_second_seller_text">
            Short description
          </div>
          <div className="mypuzzles_product_second_title_input">
            <input
              type="text"
              className="flex-elem mypuzzles_product_second_title_input_content mypuzzles_product_second_title_input_description"
              placeholder="write a short description to introduce the puzzle"
              onChange={(e) => setDescriptionValue(e.target.value)}
            />
          </div>
        </div>

        <div className="flex-elem  mypuzzles_product_all-price">
          <div className="flex-elem flex-fd-c mypuzzles_product_all-price_price">
            <div className="mypuzzles_product_second_seller_text">My price</div>
            <div className="flex-elem mypuzzles_product_second_price_currency">
              <div
                className={`flex-elem flex-fd-c mypuzzles_product_second_price_currency_money ${
                  isAllWalletActive
                    ? "mypuzzles_product_second_price_currency_all-wallet"
                    : ""
                }`}
              >
                {!isAllWalletActive ? (
                  <>
                    <div className="flex-elem mypuzzles_product_second_price_currency_money_content">
                      <img
                        className="mypuzzles_product_second_price_currency_money_img"
                        src={dollar}
                        alt="down"
                      />
                      <span className="mypuzzles_product_second_price_currency_money_text">
                        {currentWallet.name}
                      </span>
                      <img
                        className="mypuzzles_product_second_price_currency_money_select"
                        src={down}
                        alt="down"
                        onClick={() => setIsAllWalletActive(true)}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex-elem mypuzzles_product_second_price_currency_money_content">
                      <img
                        className="mypuzzles_product_second_price_currency_money_img"
                        src={dollar}
                        alt="down"
                      />
                      <span className="mypuzzles_product_second_price_currency_money_text">
                        {currentWallet.name}
                      </span>
                      <img
                        className="mypuzzles_product_second_price_currency_money_select"
                        src={down}
                        alt="down"
                        onClick={() => setIsAllWalletActive(false)}
                      />
                    </div>
                    {allWallet.map((value) => {
                      return <>{wallet_item(value.name)}</>;
                    })}
                  </>
                )}
              </div>
              <div className="mypuzzles_product_second_price_currency_line"></div>
              <div className="mypuzzles_product_second_price_currency_input">
                <input
                  className="mypuzzles_product_second_price_currency_input_content"
                  type="Number"
                  defaultValue={priceValue.toFixed(2)}
                  onChange={(e) => setPriceValue(parseFloat(e.target.value))}
                />
              </div>
            </div>
          </div>

        </div>

        <div className="flex-elem  mypuzzles_product_second_button">
          <button
            className="mypuzzles_product_second_button_quit"
            onClick={() => quit()}
          >
            Quit the creation
          </button>
          <button
            className="mypuzzles_product_second_button_next"
            onClick={() => handleNext()}
          >
            Next step
          </button>
        </div>
      </div>
      {console.log("all data step 1 : ", allDataStep1)}
    </div>
  );
};

export default ProductInformation;
