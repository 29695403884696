import React from 'react';

function Button({ onSubmit, children }) {
  return (
    <div className="button" onClick={onSubmit}>
      {children}
    </div>
  );
}

export default Button;
