/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import watch_approve from "../../../Assets/img/puzzles/watch.png";
import dollar from "../../../Assets/vectors/puzzles/dollar.svg";
import down from "../../../Assets/vectors/puzzles/down.svg";
import ApiService from "../../../Utils/ApiService";
import routes from "../../../Routes/routes.json";
import cancel from "../../../Assets/vectors/users/cancel.svg";
import gallery from "../../../Assets/vectors/puzzles/gallery.svg";
import valid from "../../../Assets/vectors/puzzles/valid.svg";
import close from "../../../Assets/vectors/puzzles/close.svg";
import "./approve.scss";

const Approve = ({
  currentPuzzle,
  handleApprovingPuzzle,
  handleClosePopup,
  handleCanceledPuzzle,
}) => {
  const [puzzle, setPuzzle] = useState(currentPuzzle);
  const [isSeller, setIsSeller] = useState(true);
  const [currentMarket, setCurrentMarket] = useState(null);
  const [currentCategorie, setCurrentCategorie] = useState(currentPuzzle.class);
  const [currentLabels, setCurrentLabels] = useState(currentPuzzle.label);
  const [isAllCurrentLabels, setIsAllCurrentLabels] = useState(false);
  const [isAllMarket, setIsAllMarket] = useState(false);
  const [isAllCategorie, setIsAllCategorie] = useState(false);
  const [allClasses, setAllClasses] = useState(null);
  const [valueTabActive, setValueTabActive] = useState("product");
  const [allFile, setAllFile] = useState([]);
  const [oldImgDeleted, setOldImgDeleted] = useState([]);
  const [startTimeValue, setStartTimeValue] = useState(null);
  const [startDateValue, setStartDateValue] = useState(null);
  const [endTimeValue, setEndTimeValue] = useState(null);
  const [endDateValue, setEndDateValue] = useState(null);
  const [isClassic, setIsClassic] = useState(true);
  const [pricePiece, setPricePiece] = useState(null);
  const [productData, setProductData] = useState({
    title: currentPuzzle.title,
    description: currentPuzzle.description,
    initial_price: currentPuzzle.initial_price,
    total_piece: currentPuzzle.total_piece,
  });
  const [dayBasis, setDayBasis] = useState(0);
  const [firstMilesParam, setFirstMilesParam] = useState(null);
  const [allLabels, setAllLabels] = useState([]);
  console.log("CATEGORIE", currentCategorie);

  useEffect(() => {
    if (puzzle) {
      if (puzzle.market === "secondary") {
        setCurrentMarket("Puzzle market");
      } else if (puzzle.market === "primary") {
        setCurrentMarket("New puzzles");
      } else {
        setCurrentMarket("Completed");
      }
      if (puzzle.link_product != "") {
        setIsSeller(false);
      }
      if (puzzle.images) {
        //setAllFile(puzzle.images);
      }
      if (!puzzle.puzzle_mode) {
        setIsClassic(true);
      }
      if (!puzzle.puzzle_mode || puzzle.puzzle_mode == "classic") {
        setIsClassic(true);
      } else {
        setIsClassic(false);
      }
      if (puzzle.date_start) {
        setStartDateValue(formatDate(new Date(puzzle.date_start)));
        setStartTimeValue(formatTime(new Date(puzzle.date_start)));
      }
      if (puzzle.date_end) {
        setEndDateValue(formatDate(new Date(puzzle.date_end)));
        setEndTimeValue(formatTime(new Date(puzzle.date_end)));
      }
      if (puzzle.total_piece && puzzle.initial_price) {
        setPricePiece(puzzle.initial_price / puzzle.total_piece);
      }
    }

    fetchAllClasses();
    fetchMilesParam();
  }, []);

  useEffect(() => {
    let data = productData;
    data["imageTodelete"] = oldImgDeleted;
    setProductData(data);
  }, [oldImgDeleted]);

  const fetchMilesParam = () => {
    const urlMilesParam = `${routes.routes.back_end.admin.puzzles.miles_param.url}`;
    const method = `${routes.routes.back_end.admin.puzzles.miles_param.method}`;

    // TODO : fetch apiData
    ApiService.fetchApiData(urlMilesParam, method, "puzzle")
      .then((result) => {
        setFirstMilesParam(result[0]);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchAllClasses = () => {
    const urlClass = `${routes.routes.back_end.admin.puzzles.classes.url}`;
    const methodClass = `${routes.routes.back_end.admin.puzzles.classes.method}`;

    ApiService.fetchApiData(urlClass, methodClass, "puzzle")
      .then((result) => {
        setAllClasses(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };

  const fetchLabels = () => {
    const urlLabels = `${routes.routes.back_end.admin.users.labels.url}`;
    const methodLabels = `${routes.routes.back_end.admin.users.labels.method}`;
    ApiService.fetchApiData(urlLabels, methodLabels, "puzzle")
      .then((result) => {
        setAllLabels(result);
      })
      .catch(() => {
        console.log("error : ");
      });
  };
  useEffect(() => {
    fetchLabels();
  }, []);
  console.log("label", allLabels);

  const handleMarket = (value) => {
    setCurrentMarket(value);
    setIsAllMarket(!isAllMarket);
  };

  const handleTab = (value) => {
    setValueTabActive(value);
  };

  const handleCategorie = (name) => {
    {
      allClasses.map((value, key) => {
        if (value.name === name) {
          setCurrentCategorie(value.name);
        }
      });
    }
    setIsAllCategorie(false);
  };
  const handleLabels = (name_label) => {
    {
      allLabels.map((value, key) => {
        if (value.name_label === name_label) {
          setCurrentLabels(value.name_label);
        }
      });
    }
    setIsAllCurrentLabels(false);
  };

  const getCurrentDate = (name) => {
    var today = new Date();
    if (name == "end") {
      today.setDate(today.getDate() + 14);
    } else if (name == "flash") {
      today.setDate(today.getDate() + 30);
    }
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0");
    var yyyy = today.getFullYear();
    today = yyyy + "-" + mm + "-" + dd;
    today = today + " 00:00";
    return today;
  };

  const getCurrentTime = (name) => {
    let today = new Date();
    let hh = String(today.getHours()).padStart(2, "0");
    let mm = String(today.getMinutes()).padStart(2, "0");

    let time = hh + ":" + mm;
    return time;
  };
  console.log("PRODUCT DTATA", productData);

  const handleApprove = () => {
    let data = productData;

    if (puzzle.state === "to approve") {
      data.state = "validated";
    }
    data.puzzle_class = currentCategorie;
    data.label = currentLabels;
    if (currentMarket === "New puzzles") {
      data.market = "primary";
    } else if (currentMarket === "Puzzle market") {
      data.market = "secondary";
    } else {
      data.market = "tertiary";
    }

    if (isClassic) {
      data["puzzle_mode"] = "classic";
    } else {
      data["puzzle_mode"] = "lighting";
    }

    //default value
    if ((!data.total_piece && !puzzle.total_piece) || data.total_piece == 0) {
      data.total_piece = puzzle.initial_price / 0.1;
    }
    if (!data.date_start && !puzzle.date_start) {
      data.date_start = getCurrentDate("start");
    }
    if (!data.data_end && !puzzle.date_end) {
      data.date_end = getCurrentDate("end");
    }
    if (!data.data_end && !puzzle.date_end) {
      data.date_end = getCurrentDate("end");
    }
    if (!data.data_flash && !puzzle.date_flash && !isClassic) {
      data.date_flash = getCurrentDate("flash");
    }
    //
    if (dayBasis != "") {
      //createMiles();
      //data.miles = true;
    }

    let dataFiles = allFile;
    console.log("dataFiles : ", dataFiles);
    //dataFiles.push(puzzle.images[0]);
    console.log("data : ", data);

    let dataMiles = {};

    dataMiles.day_basis = +dayBasis;
    dataMiles.puzzle = currentPuzzle.reference;
    dataMiles.miles_param = firstMilesParam;
    dataMiles.status = "live";
    dataMiles.minimal_days_booking = 10;
    dataMiles.minimal_days_interval = 3;

    console.log("data miles : ", dataMiles);
    handleApprovingPuzzle(puzzle, data, dataFiles, dataMiles);
    handleClosePopup();
    //window.location.reload();
  };

  const updateProductData = (e, key) => {
    let data = productData;

    if (key === "total_piece") {
      data[key] = e.target.value;
      data["available_piece"] = e.target.value;
    } else {
      if (key != "time_start" && key != "time_end") {
        data[key] = e.target.value;
      }
    }

    if (key == "date_start") {
      setStartDateValue(e.target.value);
      console.log("e.target.value : ", e.target.value);
      if (startTimeValue) {
        data["date_start"] = e.target.value + " " + startTimeValue;
      }
    }
    if (key == "time_start") {
      setStartTimeValue(e.target.value);
      if (startDateValue) {
        data["date_start"] = startDateValue + " " + e.target.value;
      }
    }

    if (key == "date_end") {
      setEndDateValue(e.target.value);
      if (endTimeValue) {
        data["date_end"] = e.target.value + " " + endTimeValue;
      }
    }
    if (key == "time_end") {
      setEndTimeValue(e.target.value);
      if (endDateValue) {
        data["date_end"] = endDateValue + " " + e.target.value;
      }
    }

    console.log("data : ", data);
    setProductData(data);
  };

  const handleReject = () => {
    handleCanceledPuzzle(puzzle, "PUT");
    handleClosePopup();
  };

  const handleClose = (value) => {
    let data = oldImgDeleted;
    data.push(value);
    setOldImgDeleted([...data]);
    //data.splice(index, 1);
    //console.log("data after change : ", data);
    //setPuzzle(data);
  };

  const handleCloseUploadedfile = (index) => {
    let data = allFile;
    data.splice(index, 1);
    setAllFile([...data]);
  };

  function handleChange(event) {
    let data = allFile;
    if (puzzle.images.length - oldImgDeleted + data.length >= 3) {
      return;
    }

    if (event.target.files.length > 0) {
      //const file = URL.createObjectURL(event.target.files[0]);
      const file = event.target.files[0];
      //setFile(file);
      data = [...allFile, file];
      setAllFile(data);
      //console.log("file : ", file);
      //console.log("data.length : ", data.length);
    }
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join("-");
  }
  function formatTime(date) {
    return [
      padTo2Digits(date.getHours() - 3),
      padTo2Digits(date.getMinutes()),
    ].join(":");
  }

  const displayFirstInputGroup = (title, valueInput, key, inputType) => {
    return (
      <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_parametres_all-input_item">
        <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input_item_text">
          {title}
        </div>
        <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input_item_input">
          <input
            className="puzzles_popup-approve_content_first_content_parametres_all-input_item_input_content"
            type={inputType}
            defaultValue={valueInput}
            onChange={(e) => updateProductData(e, key)}
          />
        </div>
      </div>
    );
  };
  const displaySecondInputGroup = (title, value, key, inputType) => {
    return (
      <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_parametres_all-input_item puzzles_popup-approve_content_first_content_parametres_all-input_item-second">
        <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input_item_text">
          {title}
        </div>
        <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input_item_input">
          <input
            className="puzzles_popup-approve_content_first_content_parametres_all-input_item_input_content"
            type={inputType}
            defaultValue={key == "price_per_piece" ? value : 0}
            onChange={(e) => updateProductData(e, key)}
            disabled={true}
          />
        </div>
      </div>
    );
  };

  console.log("day basis", puzzle);
  return (
    <>
      <div className="puzzles_popup-approve">
        <img
          className="puzzles_popup-approve_close"
          src={cancel}
          onClick={() => handleClosePopup()}
        />
        <div className="flex puzzles_popup-approve_content">
          <div className="flex puzzles_popup-approve_content_first">
            <div className="flex flex-fd-c puzzles_popup-approve_content_first_content">
              <div className="flex puzzles_popup-approve_content_first_content_title">
                {puzzle.images[0] ? (
                  <img
                    className="puzzles_popup-approve_content_first_content_title_img"
                    src={puzzle.images[0]}
                  />
                ) : (
                  <img
                    className="puzzles_popup-approve_content_first_content_title_img"
                    src={watch_approve}
                  />
                )}

                <span>{puzzle.title}</span>
              </div>
              <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_tab">
                <div className="flex puzzles_popup-approve_content_first_content_tab_text">
                  <span
                    onClick={() => handleTab("product")}
                    className={`flex product  puzzles_popup-approve_content_first_content_tab_text_item  ${
                      valueTabActive == "product"
                        ? "puzzles_popup-approve_content_first_content_tab_text_item-active"
                        : "puzzles_popup-approve_content_first_content_tab_text_item-inactive"
                    }`}
                  >
                    Product Information
                  </span>
                  <div
                    onClick={() => handleTab("media")}
                    className={`flex  puzzles_popup-approve_content_first_content_tab_text_item  ${
                      valueTabActive == "media"
                        ? "puzzles_popup-approve_content_first_content_tab_text_item-active"
                        : "puzzles_popup-approve_content_first_content_tab_text_item-inactive"
                    }`}
                  >
                    Media
                  </div>
                  <div
                    className={`flex  puzzles_popup-approve_content_first_content_tab_text_item  ${
                      valueTabActive == "specification"
                        ? "puzzles_popup-approve_content_first_content_tab_text_item-active"
                        : "puzzles_popup-approve_content_first_content_tab_text_item-inactive"
                    }`}
                    onClick={() => handleTab("specification")}
                  >
                    Specification
                  </div>
                  <div
                    className={`flex  puzzles_popup-approve_content_first_content_tab_text_item  ${
                      valueTabActive == "parameters"
                        ? "puzzles_popup-approve_content_first_content_tab_text_item-active"
                        : "puzzles_popup-approve_content_first_content_tab_text_item-inactive"
                    }`}
                    onClick={() => handleTab("parameters")}
                  >
                    Puzzle parametres
                  </div>
                  <div
                    className={`flex  puzzles_popup-approve_content_first_content_tab_text_item  ${
                      valueTabActive == "listing"
                        ? "puzzles_popup-approve_content_first_content_tab_text_item-active"
                        : "puzzles_popup-approve_content_first_content_tab_text_item-inactive"
                    }`}
                    onClick={() => handleTab("listing")}
                  >
                    Final listing price
                  </div>
                </div>
                <div className="flex puzzles_popup-approve_content_first_content_tab_line"></div>
              </div>

              {/*** */}
              {valueTabActive == "product" && (
                <>
                  <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_select">
                    <div className="puzzles_popup-approve_content_first_content_select_text">
                      Product seller details
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_select_group">
                      <div
                        className="flex puzzles_popup-approve_content_first_content_select_group_first puzzles_popup-approve_content_first_content_select_group_item"
                        onClick={() => setIsSeller(!isSeller)}
                      >
                        <div
                          className={`puzzles_popup-approve_content_first_content_select_group_second_select  ${
                            isSeller &&
                            "puzzles_popup-approve_content_first_content_select_group_second_selected"
                          }`}
                        ></div>
                        <span>I am the seller </span>
                      </div>
                      <div
                        className="flex puzzles_popup-approve_content_first_content_select_group_second puzzles_popup-approve_content_first_content_select_group_item"
                        onClick={() => setIsSeller(!isSeller)}
                      >
                        <div
                          className={`puzzles_popup-approve_content_first_content_select_group_second_select  ${
                            !isSeller &&
                            "puzzles_popup-approve_content_first_content_select_group_second_selected"
                          }`}
                        ></div>
                        <span>I am not the seller </span>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title">
                    <div className="puzzles_popup-approve_content_first_content_select_text">
                      Title
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_puzzle-title_input">
                      <input
                        type="text"
                        className="flex puzzles_popup-approve_content_first_content_puzzle-title_input_content puzzles_popup-approve_content_first_content_puzzle-title_input_content-title"
                        defaultValue={puzzle.title}
                        onChange={(e) => updateProductData(e, "title")}
                      />
                    </div>
                  </div>

                  <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title">
                    <div className="puzzles_popup-approve_content_first_content_select_text">
                      Short Description
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_puzzle-title_input">
                      <input
                        type="text"
                        className="flex puzzles_popup-approve_content_first_content_puzzle-title_input_content puzzles_popup-approve_content_first_content_puzzle-title_input_content-description"
                        defaultValue={puzzle.description}
                        onChange={(e) => updateProductData(e, "description")}
                      />
                    </div>
                  </div>

                  <div className="flex  puzzles_popup-approve_content_first_content_puzzle-container">
                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-currency">
                      <div className="puzzles_popup-approve_content_first_content_select_text">
                        Puzzle price and currency
                      </div>
                      <div className="flex puzzles_popup-approve_content_first_content_puzzle-currency_content">
                        <div className="flex puzzles_popup-approve_content_first_content_puzzle-currency_content_img">
                          <img
                            className="puzzles_popup-approve_content_first_content_puzzle-currency_content_img_icon"
                            src={dollar}
                          />
                        </div>
                        <div className="puzzles_popup-approve_content_first_content_puzzle-currency_content_line"></div>
                        <div className="puzzles_popup-approve_content_first_content_puzzle-currency_content_input">
                          <input
                            className="puzzles_popup-approve_content_first_content_puzzle-currency_content_input"
                            defaultValue={parseFloat(
                              puzzle.initial_price
                            ).toFixed(2)}
                            onChange={(e) =>
                              updateProductData(e, "initial_price")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title puzzles_popup-approve_content_first_content_puzzle-miles">
                      <div className="puzzles_popup-approve_content_first_content_select_text">
                        day basis
                      </div>
                      <div className="flex puzzles_popup-approve_content_first_content_puzzle-title_input">
                        <input
                          type="number"
                          className="flex puzzles_popup-approve_content_first_content_puzzle-title_input_content puzzles_popup-approve_content_first_content_puzzle-title_input_content-title"
                          //defaultValue={puzzle.title}
                          // value={p}
                          disabled={true}
                          onChange={(e) => setDayBasis(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {valueTabActive == "parameters" && (
                <>
                  <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_parametres">
                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_parametres_mode">
                      <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input_item_text">
                        Puzzle mode
                      </div>
                      <div className="flex puzzles_popup-approve_content_first_content_parametres_mode_items">
                        <div
                          className="flex puzzles_popup-approve_content_first_content_parametres_mode_items_item puzzles_popup-approve_content_first_content_parametres_mode_items_classic"
                          onClick={() => setIsClassic(!isClassic)}
                        >
                          <div
                            className={`puzzles_popup-approve_content_first_content_select_group_second_select  ${
                              isClassic &&
                              "puzzles_popup-approve_content_first_content_select_group_second_selected"
                            }`}
                          ></div>
                          <span>Classic</span>
                        </div>
                        <div
                          className="flex puzzles_popup-approve_content_first_content_parametres_mode_items_item puzzles_popup-approve_content_first_content_parametres_mode_items_light"
                          onClick={() => setIsClassic(!isClassic)}
                        >
                          <div
                            className={`puzzles_popup-approve_content_first_content_select_group_second_select  ${
                              !isClassic &&
                              "puzzles_popup-approve_content_first_content_select_group_second_selected"
                            }`}
                          ></div>
                          <span>Lighting</span>
                        </div>
                      </div>
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input">
                      {displayFirstInputGroup(
                        "Number of pieces",
                        puzzle.total_piece,
                        "total_piece",
                        "text"
                      )}
                      {displaySecondInputGroup(
                        "Price per piece",
                        pricePiece && pricePiece,
                        "price_per_piece",
                        "text"
                      )}
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input">
                      {displayFirstInputGroup(
                        "Start date",
                        puzzle.date_start
                          ? formatDate(new Date(puzzle.date_start))
                          : "",
                        "date_start",
                        "date"
                      )}
                      {displaySecondInputGroup(
                        "Start time",
                        formatTime(new Date(puzzle.date_start)),
                        "time_start",
                        "time"
                      )}
                    </div>

                    <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input">
                      {displayFirstInputGroup(
                        "End date",
                        puzzle.date_end
                          ? formatDate(new Date(puzzle.date_end))
                          : "",
                        "date_end",
                        "date"
                      )}
                      {displaySecondInputGroup(
                        "End time",
                        formatTime(new Date(puzzle.date_end)),
                        "time_end",
                        "time"
                      )}
                    </div>
                    <div className="flex puzzles_popup-approve_content_first_content_parametres_all-input">
                      {!isClassic &&
                        displayFirstInputGroup(
                          "Strike date",
                          puzzle.date_flash
                            ? formatDate(new Date(puzzle.date_flash))
                            : "",
                          "date_flash",
                          "date"
                        )}
                    </div>
                  </div>
                </>
              )}

              {valueTabActive == "media" && (
                <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_media">
                  <label for="file">
                    <div className="flex puzzles_popup-approve_content_first_content_media_btn">
                      <img src={gallery} />
                      <span className="puzzles_popup-approve_content_first_content_media_btn_">
                        Add pics
                      </span>
                      <input
                        id="file"
                        type="file"
                        onChange={handleChange}
                        onClick={(e) => (e.target.value = null)}
                        className="avatar_input"
                        accept="image/*"
                      />
                    </div>
                  </label>
                  <div className="flex puzzles_popup-approve_content_first_content_media-img">
                    {puzzle.images &&
                      puzzle.images.map((value, key) => {
                        if (key >= 3 || oldImgDeleted.indexOf(value) != -1) {
                          return <></>;
                        }
                        return (
                          <div className="flex puzzles_popup-approve_content_first_content_media-img_item">
                            <img
                              className="puzzles_popup-approve_content_first_content_media-img_item_img"
                              src={value}
                              alt=""
                            />
                            <img
                              className="puzzles_popup-approve_content_first_content_media-img_item_cancel"
                              src={cancel}
                              onClick={() => handleClose(value)}
                            />
                          </div>
                        );
                      })}
                    {allFile.map((value, key) => {
                      if (key >= 3) {
                        return <></>;
                      }
                      return (
                        <div className="flex puzzles_popup-approve_content_first_content_media-img_item">
                          <img
                            className="puzzles_popup-approve_content_first_content_media-img_item_img"
                            src={URL.createObjectURL(value)}
                            alt=""
                          />
                          <img
                            className="puzzles_popup-approve_content_first_content_media-img_item_cancel"
                            src={cancel}
                            onClick={() => handleCloseUploadedfile(key)}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

              {valueTabActive == "specification" && (
                <>
                  <div className="puzzles_popup-approve_content_first_content_specs">
                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title">
                      <div className="puzzles_popup-approve_content_first_content_select_text">
                        specs description
                      </div>
                      <div className="flex puzzles_popup-approve_content_first_content_puzzle-title_input">
                        <input
                          type="text"
                          className="flex  puzzles_popup-approve_content_first_content_puzzle-title_input_content-specs puzzles_popup-approve_content_first_content_puzzle-title_input_content"
                          defaultValue={puzzle.description_specs}
                          onChange={(e) =>
                            updateProductData(e, "description_specs")
                          }
                        />
                      </div>
                    </div>

                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title">
                      <div className="puzzles_popup-approve_content_first_content_select_text">
                        link
                      </div>
                      <div className="flex puzzles_popup-approve_content_first_content_puzzle-title_input">
                        <input
                          type="text"
                          className="flex  puzzles_popup-approve_content_first_content_puzzle-title_input_content-title puzzles_popup-approve_content_first_content_specs_link"
                          defaultValue={puzzle.link_specs}
                          onChange={(e) => updateProductData(e, "link_specs")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              {valueTabActive == "listing" && (
                <>
                  <div className="puzzles_popup-approve_content_first_content_specs">
                    <div className="flex flex-fd-c puzzles_popup-approve_content_first_content_puzzle-title">
                      <div className="flex-elem mypuzzles_confirmation_content_all_down">
                        <div>Number of pieces</div>
                        <div>
                          <input
                            className="puzzles_popup-approve_content_first_content_parametres_all-input_item_input_content"
                            defaultValue={puzzle?.total_piece}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="flex-elem mypuzzles_confirmation_content_all_down">
                        <div>Price per piece</div>
                        <div>
                          <input
                            className="puzzles_popup-approve_content_first_content_parametres_all-input_item_input_content"
                            defaultValue={
                              "$" +
                              (
                                puzzle?.initial_price / puzzle?.total_piece
                              ).toFixed(2)
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="flex-elem mypuzzles_confirmation_content_all_down">
                        <div>Final Listing Price</div>
                        <div>
                          <input
                            className="puzzles_popup-approve_content_first_content_parametres_all-input_item_input_content"
                            defaultValue={
                              "$" + puzzle?.total_piece * pricePiece.toFixed(2)
                            }
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {/** */}
            </div>
          </div>
          <div className="flex puzzles_popup-approve_content_second">
            <div className="flex flex-fd-c puzzles_popup-approve_content_second_content">
              <div className="flex puzzles_popup-approve_content_first_content_title">
                <span>Admin options</span>
              </div>

              <div
                className={`flex flex-fd-c puzzles_popup-approve_content_second_content_categorie  ${
                  isAllCategorie &&
                  "puzzles_popup-approve_content_second_content_show"
                }`}
              >
                <div className="puzzles_popup-approve_content_first_content_select_text">
                  Categorie
                </div>

                <div className="flex flex-fd-c puzzles_popup-approve_content_second_content_categorie_select">
                  <div
                    className="flex puzzles_popup-approve_content_second_content_categorie_select_content"
                    onClick={() => setIsAllCategorie(!isAllCategorie)}
                  >
                    <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                      {currentCategorie}
                    </span>
                    <img src={down} />
                  </div>
                  {isAllCategorie && (
                    <>
                      {allClasses.map((value) => {
                        return (
                          <div
                            className="flex puzzles_popup-approve_content_second_content_categorie_select_content puzzles_popup-approve_content_second_content_categorie_select_content_item-down"
                            onClick={() => handleCategorie(value.name)}
                          >
                            <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                              {value.name}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>

              <div
                className={`flex flex-fd-c puzzles_popup-approve_content_second_content_first  ${
                  isAllMarket &&
                  "puzzles_popup-approve_content_second_content_show"
                }`}
              >
                <div className="puzzles_popup-approve_content_first_content_select_text">
                  Market
                </div>

                <div className="flex flex-fd-c puzzles_popup-approve_content_second_content_categorie_select">
                  <div
                    className="flex puzzles_popup-approve_content_second_content_categorie_select_content"
                    onClick={() => setIsAllMarket(!isAllMarket)}
                  >
                    <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                      {currentMarket}
                    </span>
                    <img src={down} />
                  </div>
                  {isAllMarket && (
                    <>
                      <div
                        className="flex puzzles_popup-approve_content_second_content_categorie_select_content puzzles_popup-approve_content_second_content_categorie_select_content_item-down"
                        onClick={() => handleMarket("Puzzle market")}
                      >
                        <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                          Puzzle market
                        </span>
                      </div>
                      <div
                        className="flex puzzles_popup-approve_content_second_content_categorie_select_content puzzles_popup-approve_content_second_content_categorie_select_content_item-down"
                        onClick={() => handleMarket("New puzzles")}
                      >
                        <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                          New Puzzles
                        </span>
                      </div>
                      <div
                        className="flex puzzles_popup-approve_content_second_content_categorie_select_content puzzles_popup-approve_content_second_content_categorie_select_content_item-down"
                        onClick={() => handleMarket("Completed")}
                      >
                        <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                          Completed
                        </span>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-fd-c puzzles_popup-approve_content_second_content_label">
                <div className="puzzles_popup-approve_content_first_content_select_text">
                  Label
                </div>

                <div className="flex flex-fd-c puzzles_popup-approve_content_second_content_categorie_select">
                  <div
                    className="flex puzzles_popup-approve_content_second_content_categorie_select_content"
                    onClick={() => setIsAllCurrentLabels(!isAllCurrentLabels)}
                  >
                    <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                      {currentLabels}
                    </span>
                    <img src={down} />
                  </div>
                  {isAllCurrentLabels && (
                    <div className="dropdown_label">
                      {allLabels.map((value) => {
                        return (
                          <div
                            className="flex puzzles_popup-approve_content_second_content_categorie_select_content puzzles_popup-approve_content_second_content_categorie_select_content_item-down"
                            onClick={() => handleLabels(value.name_label)}
                          >
                            <span className="puzzles_popup-approve_content_second_content_categorie_select_content_text">
                              {value.name_label}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-fd-c puzzles_popup-approve_content_second_content_exchange">
                <div className="puzzles_popup-approve_content_first_content_select_text-exchange">
                  Pieces required
                </div>
                <div className="flex  puzzles_popup-approve_content_second_content_exchange">
                  <input
                    type="number"
                    className="flex  puzzles_popup-approve_content_second_content_exchange_input"
                    defaultValue={puzzle.piece_required}
                    onChange={(e) => updateProductData(e, "piece_required")}
                  />
                </div>
              </div>

              <div className="flex puzzles_popup-approve_content_second_content_all-btn">
                <button
                  className="flex puzzles_popup-approve_content_second_content_all-btn_item puzzles_popup-approve_content_second_content_all-btn_reject"
                  onClick={() => handleReject()}
                >
                  <img
                    className="puzzles_popup-approve_content_second_content_all-btn_item_img"
                    src={close}
                  />
                  <span>Reject</span>
                </button>
                <button
                  className="flex puzzles_popup-approve_content_second_content_all-btn_item puzzles_popup-approve_content_second_content_all-btn_approve"
                  onClick={() => handleApprove()}
                >
                  <img
                    className="puzzles_popup-approve_content_second_content_all-btn_item_img"
                    src={valid}
                  />
                  <span>Approve</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        {firstMilesParam && console.log("firstMilesPraam  : ", firstMilesParam)}
      </div>
    </>
  );
};

export default Approve;
