import React, { useState } from 'react';
import './ButtonProfil.scss'

function ButtonProfil({ onSubmit, children }) {
  return (
    <div className="button" onClick={onSubmit}>
      {children}
    </div>
  );
}

export default ButtonProfil;