import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import ApiService from "../../../../Utils/ApiService";
import routes from "../../../../Routes/routes.json";
import "./FAQAnswer.scss"

const fetchLike = async ({ user, answer }) => {
	const { url, method } = routes.routes.back_end.admin.users.faq_likes
	return await ApiService.sendingPutData(url, method, { user, answer }, "user")
}

export default function FaqAnswer({ answer, update }) {
	const [liked, setLiked] = useState(false)
	const { user } = useSelector((store) => store.Auth)
	useEffect(() => {
		const found = answer.likes.find(like => like._id.$oid === user.id)
		setLiked(!!found)
	}, [answer])

	return (
		<div className='FAQAnswer'>
			<div className='answers-content'>
				<div className='username'>
					@{answer.user.username}
				</div>
				<div className='answer'>
					{answer.content}
				</div>
			</div>

			<div className='answers-like'>
				<div className={liked ? "like" : "unlike"}
					onClick={async () => {
						setLiked(!liked)
						await fetchLike({
							user: user.id, answer: answer.id
						})
						update()
					}}
				></div>
				<div className='like-label'>{liked ? "Liked" : "Like "}</div>
			</div>
		</div>
	)
}
