/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
import configFile from "./config.json";
const APP_ENV = "production"

class config {
  getBackEndUrl(value) {
    switch (APP_ENV) {
      case "production":
        if (value == "user"){return `${configFile.url_back_end.production.user}`;}
        else if(value == "transactions"){ return `${configFile.url_back_end.production.transacions}`;}
        else if(value == "puzzle"){return `${configFile.url_back_end.production.puzzle}`;}
        else{ return `${configFile.url_back_end.production.transacions}`;}
      case "staging":
        if (value == "user"){return `${configFile.url_back_end.staging.user}`;}
        else if(value == "transactions"){ return `${configFile.url_back_end.staging.transacions}`;}
        else if(value == "puzzle"){return `${configFile.url_back_end.staging.puzzle}`;}
        else{ return `${configFile.url_back_end.staging.transacions}`;}
      case "development":
        if (value == "user"){return `${configFile.url_back_end.development.user}`;}
        else if(value == "transactions"){ return `${configFile.url_back_end.development.transacions}`;}
        else if(value == "puzzle"){return `${configFile.url_back_end.development.puzzle}`;}
        else{ return `${configFile.url_back_end.dev.transacions}`;}
      case "testing":
        if (value == "user"){return `${configFile.url_back_end.testing.user}`;}
        else if(value == "transactions"){ return `${configFile.url_back_end.testing.transacions}`;}
        else if(value == "puzzle"){return `${configFile.url_back_end.testing.puzzle}`;}
        else{ return `${configFile.url_back_end.testing.transacions}`;}
      case "local":
        if (value == "user"){return `${configFile.url_back_end.local.user}`;}
        else if(value == "transactions"){ return `${configFile.url_back_end.local.transacions}`;}
        else if(value == "puzzle"){return `${configFile.url_back_end.local.puzzle}`;}
        else{ return `${configFile.url_back_end.local.transacions}`;}
    }
  }
}

export default new config();
