import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./Reducers/rootReducer";

const persistConfig = {
  key: "root",
  storage,
  blacklist:["banner", "notification", "faq"]
};

// ----------------------------------------------------------------------

const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false
  })
});



//let store = createStore(persistedReducer);
let persistor = persistStore(store);

export { store, persistor };

