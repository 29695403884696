import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import ApiService from '../../Utils/ApiService';

const initialState = {
  faqs: [],
  isLoading: false,
  error: { status: false, message: '' },
};

export const getFaqs = createAsyncThunk(
  'faq/get',
  async (data, thunkAPI) => {
    try {
      const res = await ApiService.fetchApiData(
        '/faqs',
        'GET',
        'user'
      );
      console.log("Eto")
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue({ status: true, message: error.message });
    }
  }
);

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  extraReducers: {
    // get all notifications
    [getFaqs.pending]: (state) => {
      state.isLoading = true;
    },
    [getFaqs.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.faqs = action.payload;
    },
    [getFaqs.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default faqSlice.reducer;
