import React, { useCallback, useState } from 'react'
import Modal from '../../Modal';
import BtnSetting from '../../SettingButton';
import AddMember from './AddMember';
import './EditGroup.scss'

export default function EditGroup() {


  const [addMember, setAddMember] = useState(false);
  const [editName, setEditName] = useState()

  const showAddMember = useCallback(() => {
    setAddMember(true);
  }, []);

  const onClose = useCallback(() => {
    setAddMember(false);
  }, []);
  return (
    <div className='EditGroup'>
      <div className='group-name-wrapper'>
        <label htmlFor='group-name'>
          Group name
        </label>
        <input
          type="text"
          id='group-name'
          placeholder='Enter the name of group'
          onChange={(e) => setEditName(e.target.value)} />
      </div>
      <div className='group-title'>
        <span>Members</span>
        <span onClick={showAddMember}>+ Add members</span>
        <Modal isOpen={addMember} title="Create custom group" onClose={onClose}>
          <AddMember />
        </Modal>
      </div>
      <div className='users-list'>
        <div className='user-item-container'>
          <div className='user-item'>
            <div className='profile-pic' />
            <label>eddy97</label>
          </div>
          <div className='trash'/>
        </div>
      </div>
      <div className='decision-buttons'>
        <BtnSetting text='Cancel' className='cancel' />
        <BtnSetting text='Delete' className='cancel' />
        <BtnSetting text='Save' className='confirm' />
      </div>
    </div>
  )
}
