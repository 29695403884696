import React from 'react'
import UserItem from '../../../UserItem';
import './AddMember.scss'

export default function AddMember() {
  return (
    <div className='AddMember'>
      <div className='add-title'>
        Select user from your link
      </div>
      <UserItem/>
      <div className='decision-buttons'>
        <div className='block-btn cancel'>Cancel</div>
        <div className='block-btn confirm'>Add Member</div>
      </div>
    </div>
  )
}
