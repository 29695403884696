import React, { useState } from "react";
import Input from "../Input";
import gallery from "../../../Assets/vectors//Mypuzzles/gallery.svg";
import docs from "../../../Assets/vectors/Mypuzzles/docs.svg";
import link from "../../../Assets/vectors/Mypuzzles/link.svg";
import close from "../../../Assets/vectors/Mypuzzles/close.svg";
import "./style.scss"

const VerificationAccount = () => {
  const [firstName, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [date, setDate] = useState("");
  const [isLinkActive, setIsLinkActive] = useState(false);
  const [file, setFile] = useState(null);
  const [linkSpecsValue, setLinkSpecsValue] = useState("");

  const handleChange = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      setFile(file);
    }
  };

  const handleCloseDocs = () => {
    setFile(null);
  };

  return (
    <div className="NewFAQ">
      <div className="form">
        <div className="container-input">
          <Input
            label="FirstName"
            placeholder="First Name"
            type="text"
            name="firstname"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
          />
          <Input
            label="LastName"
            placeholder="Last Name"
            type="text"
            name="lastname"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
          />
          <Input
            label="Birth Date"
            placeholder="Birth date"
            type="date"
            name="birthdate"
            onChange={(e) => {
              setDate(e.target.value);
            }}
          />
        </div>
        <div className="flex-elem flex-fd-c mypuzzles_specification_file-link">
          <div className="flex-elem  mypuzzles_specification_file-link_all-btn">
            <div
              className="flex-elem mypuzzles_specification_file-link_all-btn_file mypuzzles_specification_file-link_all-btn_file_link"
              onClick={() => setIsLinkActive(true)}
            >
              <span className="mypuzzles_specification_file-link_all-btn_file_text">
                Add Your ID Card
              </span>
            </div>
          </div>
        </div>
        {isLinkActive && (
          <div className="mypuzzles_specification_link-input-container">
            <div className="flex-elem mypuzzles_specification_link-input">
              <img
                className="mypuzzles_specification_link-input_icon"
                src={link}
                alt="link"
              />
              <input
                type="text"
                className="mypuzzles_specification_link-input_input"
                onChange={(e) => setLinkSpecsValue(e.target.value)}
              />
            </div>
            <img
              className="mypuzzles_specification_link-input_close"
              src={close}
              alt="close"
              onClick={() => setIsLinkActive(false)}
            />
          </div>
        )}
      </div>

      <div className="buttons">
        <button className="button">Verify My identity</button>
      </div>
    </div>
  );
};

export default VerificationAccount;
