import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react'
import BtnSetting from '../BtnSetting';
import UserItem from '../UserItem'
import './Group.scss'



export default function Groups() {
  const [groupName, setGroupName] = useState('');

  const checkEmpty = useEffect (() => {
    if (Object.keys(groupName).length === 0) {
      console.log('Object is empty');
    }
    else{
      console.log('not empty')
    }

     
  },[])

  

  return (
    <div className='Groups'>
      <div className='group-name-wrapper'>
        <label htmlFor='group-name'>
          Group name
        </label>
        <input
          type="text"
          id='group-name'
          placeholder='Group name'
          onChange={(e) => setGroupName(e.target.value)}
        />
      </div>
      <div className='group-title'>
        <span>Select user from your link</span>
        <span>Select all</span>
      </div>
      <div className='users-list'>
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
        <UserItem />
      </div>
      <div className='decision-buttons'>
        <BtnSetting text='Cancel' className='cancel' /> 
        <BtnSetting onClick={checkEmpty} text='Create Group' className='confirm' /> 
      </div>
    </div>
  )
}
