import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiService from "../../Utils/ApiService";

const initialState = {
  notifications: [],
  isLoading: false,
  error: { status: false, message: "" },
};

export const getNotifications = createAsyncThunk(
  "notification/get",
  async (data, thunkAPI) => {
    try {
      const res = await ApiService.fetchApiData(
        `/notification/all`,
        "GET",
        "user"
      );
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue({ status: true, message: error.message });
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  extraReducers: {
    // get all notifications
    [getNotifications.pending]: (state) => {
      state.isLoading = true;
    },
    [getNotifications.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.notifications = action.payload;
    },
    [getNotifications.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default notificationSlice.reducer;
