import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import ApiService from "../../Utils/ApiService";

const initialState = {
  banners: [],
  isLoading: false,
  error: { status: false, message: "" },
};

export const getallbanners = createAsyncThunk(
  "banner/get",
  async (data, thunkAPI) => {
    try {
      const res = await ApiService.fetchApiData("/list/banners", "GET", "user");

      console.log("here, Ifetch: ", data);
      return res;
    } catch (err) {
      return thunkAPI.rejectWithValue({ status: true, message: err.message });
    }
  }
);

export const createbanner = createAsyncThunk(
  "banner/add",
  async (data, thunkAPI) => {
    try {
      const res = await ApiService.postFormData(
        "/banner",
        "PUT",
        data,
        "user"
      );
      return res;
    } catch (error) {
      return thunkAPI.rejectWithValue({ status: true, message: error.message });
    }
  }
);

const bannerSlice = createSlice({
  name: "banner",
  initialState,
  extraReducers: {
    // get all banners
    [getallbanners.pending]: (state) => {
      state.isLoading = true;
    },
    [getallbanners.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.banners = action.payload;
    },
    [getallbanners.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },

    // create banner
    [createbanner.pending]: (state) => {
      state.isLoading = true;
    },
    [createbanner.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.banners.push(action.payload);
    },
    [createbanner.rejected]: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

export default bannerSlice.reducer;
