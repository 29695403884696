import { createFormData } from '.';
import config from '../Config/config';

class ApiService {
  async fetchApiData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    console.log(method, 'method');
    console.log(fullUrl, 'full-url');

    return fetch(fullUrl, {
      method: method,
    }).then((response) => response.json());
  }

  async sendingPutData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    console.log(data, 'data');
    console.log(method, 'method');

    return fetch(fullUrl, {
      method: method,
      body: JSON.stringify(data),
    }).then((response) => response.json());
  }

  async postWithoutData(route, method, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    return fetch(fullUrl, {
      method: method,
    }).then((response) => response.json());
  }

  async fetchApiFormData(route, method, data, files, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = new FormData();

    for (const [key, value] of Object.entries(data)) {
      formData.append(key, value);
    }
    for (let value of files) {
      formData.append('file', value);
    }

    console.log('full url : ', fullUrl);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) => response.json());
  }

  async postFormData(route, method, data, service) {
    const fullUrl = config.getBackEndUrl(service) + route;
    const formData = createFormData(data);
    console.log('full url : ', fullUrl);
    return fetch(fullUrl, {
      method: method,
      body: formData,
    }).then((response) => response.json());
  }

  async compare_az(a, b) {
    if (a.username.toLowerCase() < b.username.toLowerCase()) {
      return -1;
    }
    if (a.username.toLowerCase() > b.username.toLowerCase()) {
      return 1;
    }
    return 0;
  }
}

export default new ApiService();
